var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mr-6 mt-2"},[_c('div',{staticClass:"d-flex"},[(
          _vm.productName !== _vm.PRODUCT_CODES.pensionbee &&
            _vm.productName !== _vm.PRODUCT_CODES.firefighter
        )?_c('v-menu',{attrs:{"offset-y":"","open-on-hover":"","content-class":"feature-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text--white nav-header-btn font-regular",attrs:{"text":"","color":"#fff","plain":""}},'v-btn',attrs,false),on),[_vm._v("Features")])]}}],null,false,438054561)},[_vm._v(" "),_c('v-card',{staticClass:"d-flex black--text p-2",attrs:{"tile":""}},_vm._l((_vm.featureNames),function(featureVal,index){return _c('div',{key:index},[_c('v-list',{staticClass:"mr-2",attrs:{"dense":"","shaped":""}},[_c('v-list-item',{on:{"click":function($event){_vm.redirectToFeature(featureVal.toLowerCase(), '')}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[(index == 0 || index == 2)?_c('v-icon',{staticClass:"black--text"},[_vm._v(_vm._s(index == 0 ? 'account_balance' : 'compare_arrows'))]):_c('img',{attrs:{"alt":"benchmark-irr-icon","src":require("../../assets/Icons/benchmark-irr-icon-blacksvg.svg"),"height":"100%","width":"100%"}})],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(featureVal))])],1),_vm._v(" "),_c('v-divider',{staticClass:"ml-2 mb-2"}),_vm._v(" "),_vm._l((_vm.featureList[featureVal]),function(featureName,i){return _c('v-list-item',{key:i,on:{"click":function($event){_vm.redirectToFeature(
                    featureVal.toLowerCase(),
                    featureName.toLowerCase()
                  )}}},[_c('v-list-item-title',[_vm._v(_vm._s(featureName))])],1)})],2)],1)}),0)],1):_vm._e(),_vm._v(" "),_c('v-btn',{staticClass:"text--white font-regular",attrs:{"text":"","color":"#fff","plain":""},on:{"click":function($event){_vm.$router.push('/aboutus');
          _vm.callGAEvent(_vm.$gtag, {
            eventName: 'about_us_btn',
            category: 'btn_click'
          });}}},[_vm._v("About Us")]),_vm._v(" "),_c('v-btn',{staticClass:"text--white font-regular",attrs:{"text":"","color":"#fff","plain":""},on:{"click":function($event){_vm.callGAEvent(_vm.$gtag, {
            eventName: 'help_btn',
            category: 'menu_click'
          });
          _vm.$router.push('/help');}}},[_vm._v("Help")]),_vm._v(" "),_c('v-btn',{staticClass:"text--white font-regular",attrs:{"text":"","color":"#fff","plain":""},on:{"click":function($event){return _vm.clickContactUs()}}},[_vm._v("Contact Us")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }