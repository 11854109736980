var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('client-only',[_c('FixedHeader',{attrs:{"threshold":100,"header-class":"vue-fixed-header vue-fixed-header--isFixed","hide-scroll-up":false}},[_c('nav',{staticClass:"navbar py-4 sm:bg-transparent z-10 relative",class:_vm.isCIPPPage ? 'cipp-gradient-purple' : 'gradient-purple'},[_c('div',{staticClass:"m-auto flex justify-center sm:justify-between hidden-sm-and-down w-11/12"},[_c('nuxt-link',{staticClass:"d-flex",attrs:{"to":"/"}},[_c('img',{staticClass:"my-auto",staticStyle:{"height":"40px"},attrs:{"alt":"logo","src":require("../../assets/AW-logos/logo-3x-h.png")}})]),_vm._v(" "),(_vm.productName == _vm.PRODUCT_CODES.pensionbee)?_c('img',{staticClass:"mx-auto pensionbee-img ml-6",attrs:{"src":require("../../assets/AW-logos/pensionbee-img.png"),"alt":"pension-bee"}}):_vm._e(),_vm._v(" "),(!_vm.isCIPPPage)?_c('div',{staticClass:"flex justify-end m-auto flex-1"},[_c('FeaturesTab',{attrs:{"feature-names":_vm.featureNames,"feature-list":_vm.featureList,"click-contact-us":_vm.clickContactUs,"redirect-to-feature":_vm.redirectToFeature}})],1):_vm._e(),_vm._v(" "),(
              !_vm.isCIPPPage &&
                _vm.productName !== _vm.PRODUCT_CODES.main &&
                _vm.productName !== _vm.PRODUCT_CODES.develop
            )?_c('div',{staticClass:"m-auto",attrs:{"id":"wrapper1"}},[_c('div',{attrs:{"id":"left2"}},[(_vm.productName == _vm.PRODUCT_CODES.firefighter)?_c('button',{staticClass:"contact-btn index-page font-regular py-0 px-0 text-black rounded-full appearance-none",class:_vm.$store.state.user.state.loggedIn
                    ? 'btn-yellow'
                    : 'white--text',style:(_vm.$store.state.user.state.loggedIn
                    ? 'width:12rem !important;'
                    : 'border: 1px solid;'),on:{"click":function($event){return _vm.redirectToDashboard()}}},[_vm._v("\n                "+_vm._s(_vm.$store.state.user.state.loggedIn
                    ? 'Calculate Tax Charge'
                    : 'Login')+"\n              ")]):_c('button',{staticClass:"contact-btn index-page font-regular py-0 px-0 text-black rounded-full appearance-none",class:_vm.$store.state.user.state.loggedIn
                    ? 'btn-yellow'
                    : 'white--text',style:(_vm.$store.state.user.state.loggedIn ? '' : 'border: 1px solid;'),on:{"click":function($event){return _vm.redirectToDashboard()}}},[_vm._v("\n                "+_vm._s(_vm.$store.state.user.state.loggedIn ? 'My Dashboard' : 'Login')+"\n              ")])]),_vm._v(" "),_c('div',{attrs:{"id":"middle2"}}),_vm._v(" "),_c('div',{attrs:{"id":"right2"}},[(!_vm.$store.state.user.state.loggedIn)?_c('button',{staticClass:"contact-btn index-page btn-yellow text-black font-regular py-0 px-0 rounded-full appearance-none",on:{"click":function($event){_vm.$router.push('/onboarding/personal-info');
                  _vm.callGAEvent(_vm.$gtag, {
                    eventName: 'register_btn',
                    category: 'btn_click'
                  });}}},[_vm._v("\n                Register\n              ")]):_vm._e()])]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"hidden-md-and-up flex ml-4 mr-4"},[_c('v-row',[_c('v-col',{staticClass:"my-auto pa-0",class:_vm.productName == _vm.PRODUCT_CODES.pensionbee ? '-ml-2' : '',attrs:{"md":2,"sm":2,"xs":12}},[_c('nuxt-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"mx-auto",style:(_vm.productName == _vm.PRODUCT_CODES.pensionbee
                      ? 'height: 50px'
                      : 'height: 40px;'),attrs:{"alt":"logo","src":require("../../assets/AW-logos/logo@2x.png")}})])],1),_vm._v(" "),(_vm.productName == _vm.PRODUCT_CODES.pensionbee)?_c('v-col',{staticClass:"my-auto pa-0 -ml-1",attrs:{"md":2,"sm":2,"xs":12}},[_c('img',{staticClass:"mx-auto",staticStyle:{"height":"60px"},attrs:{"src":require("../../assets/AW-logos/pensionbee-img.png"),"alt":"pension-bee"}})]):_vm._e(),_vm._v(" "),(_vm.productName == _vm.PRODUCT_CODES.pensionbee)?_c('v-col'):_vm._e(),_vm._v(" "),(
                _vm.productName !== _vm.PRODUCT_CODES.pensionbee &&
                  _vm.productName !== _vm.PRODUCT_CODES.firefighter
              )?_c('v-col',{staticClass:"my-auto pa-0",attrs:{"md":8,"xs":0,"sm":8}}):_vm._e(),_vm._v(" "),_c('FeaturesTabMobile',{attrs:{"feature-names":_vm.featureNames,"feature-list":_vm.featureList,"redirect-to-feature":_vm.redirectToFeature,"click-contact-us":_vm.clickContactUs,"redirect-to-dashboard":_vm.redirectToDashboard}})],1)],1)])])],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.showContactForm),callback:function ($$v) {_vm.showContactForm=$$v},expression:"showContactForm"}},[_c('ContactUsDialog',{attrs:{"close-contact-dialog":_vm.closeContactForm}})],1),_vm._v(" "),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color ? _vm.snackbar.color : '',"bottom":"","timeout":_vm.snackbar.timeout},model:{value:(_vm.snackbar.model),callback:function ($$v) {_vm.$set(_vm.snackbar, "model", $$v)},expression:"snackbar.model"}},[_vm._v("\n    "+_vm._s(_vm.snackbar.text)+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }