<template>
  <div>
    <client-only>
      <FixedHeader
        :threshold="100"
        header-class="vue-fixed-header vue-fixed-header--isFixed"
        :hide-scroll-up="false"
      >
        <nav
          class="navbar py-4 sm:bg-transparent z-10 relative"
          :class="isCIPPPage ? 'cipp-gradient-purple' : 'gradient-purple'"
        >
          <div
            class="m-auto flex justify-center sm:justify-between hidden-sm-and-down w-11/12"
          >
            <nuxt-link to="/" class="d-flex">
              <img
                style="height: 40px"
                alt="logo"
                class="my-auto"
                src="../../assets/AW-logos/logo-3x-h.png"
              />
            </nuxt-link>
            <img
              v-if="productName == PRODUCT_CODES.pensionbee"
              src="../../assets/AW-logos/pensionbee-img.png"
              class="mx-auto pensionbee-img ml-6"
              alt="pension-bee"
            />
            <div v-if="!isCIPPPage" class="flex justify-end m-auto flex-1">
              <FeaturesTab
                :feature-names="featureNames"
                :feature-list="featureList"
                :click-contact-us="clickContactUs"
                :redirect-to-feature="redirectToFeature"
              />
            </div>
            <div
              v-if="
                !isCIPPPage &&
                  productName !== PRODUCT_CODES.main &&
                  productName !== PRODUCT_CODES.develop
              "
              id="wrapper1"
              class="m-auto"
            >
              <div id="left2">
                <button
                  v-if="productName == PRODUCT_CODES.firefighter"
                  class="contact-btn index-page font-regular py-0 px-0 text-black rounded-full appearance-none"
                  :class="
                    $store.state.user.state.loggedIn
                      ? 'btn-yellow'
                      : 'white--text'
                  "
                  :style="
                    $store.state.user.state.loggedIn
                      ? 'width:12rem !important;'
                      : 'border: 1px solid;'
                  "
                  @click="redirectToDashboard()"
                >
                  {{
                    $store.state.user.state.loggedIn
                      ? 'Calculate Tax Charge'
                      : 'Login'
                  }}
                </button>
                <button
                  v-else
                  class="contact-btn index-page font-regular py-0 px-0 text-black rounded-full appearance-none"
                  :class="
                    $store.state.user.state.loggedIn
                      ? 'btn-yellow'
                      : 'white--text'
                  "
                  :style="
                    $store.state.user.state.loggedIn ? '' : 'border: 1px solid;'
                  "
                  @click="redirectToDashboard()"
                >
                  {{
                    $store.state.user.state.loggedIn ? 'My Dashboard' : 'Login'
                  }}
                </button>
              </div>
              <div id="middle2"></div>
              <div id="right2">
                <button
                  v-if="!$store.state.user.state.loggedIn"
                  class="contact-btn index-page btn-yellow text-black font-regular py-0 px-0 rounded-full appearance-none"
                  @click="
                    $router.push('/onboarding/personal-info');
                    callGAEvent($gtag, {
                      eventName: 'register_btn',
                      category: 'btn_click'
                    });
                  "
                >
                  Register
                </button>
              </div>
            </div>
          </div>
          <div class="hidden-md-and-up flex ml-4 mr-4">
            <v-row>
              <v-col
                :md="2"
                :sm="2"
                :xs="12"
                class="my-auto pa-0"
                :class="productName == PRODUCT_CODES.pensionbee ? '-ml-2' : ''"
              >
                <nuxt-link to="/">
                  <img
                    :style="
                      productName == PRODUCT_CODES.pensionbee
                        ? 'height: 50px'
                        : 'height: 40px;'
                    "
                    alt="logo"
                    src="../../assets/AW-logos/logo@2x.png"
                    class="mx-auto"
                  />
                </nuxt-link>
              </v-col>
              <v-col
                v-if="productName == PRODUCT_CODES.pensionbee"
                :md="2"
                :sm="2"
                :xs="12"
                class="my-auto pa-0 -ml-1"
              >
                <img
                  src="../../assets/AW-logos/pensionbee-img.png"
                  class="mx-auto"
                  style="height: 60px;"
                  alt="pension-bee"
                />
              </v-col>
              <v-col v-if="productName == PRODUCT_CODES.pensionbee" />
              <v-col
                v-if="
                  productName !== PRODUCT_CODES.pensionbee &&
                    productName !== PRODUCT_CODES.firefighter
                "
                :md="8"
                :xs="0"
                :sm="8"
                class="my-auto pa-0"
              >
              </v-col>
              <FeaturesTabMobile
                :feature-names="featureNames"
                :feature-list="featureList"
                :redirect-to-feature="redirectToFeature"
                :click-contact-us="clickContactUs"
                :redirect-to-dashboard="redirectToDashboard"
              />
            </v-row>
          </div>
        </nav>
      </FixedHeader>
    </client-only>

    <v-dialog v-model="showContactForm" max-width="600px">
      <ContactUsDialog :close-contact-dialog="closeContactForm" />
    </v-dialog>
    <v-snackbar
      v-model="snackbar.model"
      :color="snackbar.color ? snackbar.color : ''"
      bottom
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>
<script>
import FixedHeader from 'vue-fixed-header';
import FeaturesTab from '~/components/Header/FeaturesTab.vue';
import FeaturesTabMobile from '~/components/Header/MobileView/FeaturesTab.vue';
import { callGAEvent } from '~/plugins/utils.js';
import { PRODUCT_CODES } from '~/constants';

export default {
  components: {
    FixedHeader,
    FeaturesTab,
    FeaturesTabMobile,
    ContactUsDialog: () => import('~/components/ContactUsDialog.vue')
  },
  data() {
    return {
      PRODUCT_CODES,
      productName: this.$productName,
      showContactForm: false,
      isHelpPage: !!this.$router.history.current.fullPath.includes('help'),
      isCIPPPage: !!this.$router.history.current.fullPath.includes(
        'cipp-offer'
      ),
      featureNames: '',
      showMenu: false,
      currView: this.$store.state.landingPage.currView,
      featureNameList: {
        personal: ['ACCOUNT', 'RETIREMENT', 'NEXT STEPS'],
        business: ['ANALYTICS', 'COMMUNICATION', 'MEMBER SUPPORT']
      },
      featureList: {
        ACCOUNT: ['Pension Finding', 'AgeWage Score', 'Pension Dashboard'],
        RETIREMENT: [
          'Current AgeWage',
          'State Pension',
          'Retirement Calculator'
        ],
        'NEXT STEPS': [
          'Consolidation',
          'Cashflow Modelling',
          'Financial Advice'
        ],
        ANALYTICS: [
          'Value For Money',
          'Risk Adjusted Performance',
          'Data Auditing & Cleansing'
        ],
        COMMUNICATION: [
          'Score Integration To SMPI',
          'Member Messaging',
          'Investment Pathways'
        ],
        'MEMBER SUPPORT': [
          'Consolidation',
          'Cashflow Modelling',
          'Financial Advice'
        ]
      },
      snackbar: {
        model: false,
        text: '',
        color: 'red',
        timeout: 2000
      }
    };
  },
  watch: {
    $route() {
      this.isHelpPage = this.$router.history.current.fullPath.includes('help');
      this.isCIPPPage = !!this.$router.history.current.fullPath.includes(
        'cipp-offer'
      );
    }
  },
  created() {
    const that = this;
    this.$nuxt.$on('loadingContactDialog', (data) => {
      if (data) {
        that.showContactForm = false;
        this.snackbar.model = true;
        this.snackbar.text = 'Email sent successfully.';
        this.snackbar.color = 'green';
        this.snackbar.timeout = 5000;
      } else {
        this.snackbar.model = true;
        this.snackbar.text = 'Error sending contact email.';
        this.snackbar.color = 'red';
        this.snackbar.timeout = 5000;
      }
    });
  },
  mounted() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
    this.featureNames = this.featureNameList[this.currView];
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    callGAEvent,
    resizeHandler() {
      // if (window.innerWidth >= 1264) {
      //   this.showMenu = false;
      // } else {
      //   this.showMenu = true;
      // }
    },
    changeTab(view) {
      this.$store.commit('setCurrView', view);
      this.currView = view;
      this.featureNames = this.featureNameList[view];
      window.scrollTo(0, 0);
      if (this.$router.history.current.fullPath !== '/') {
        this.$router.push({
          path: `/`
        });
      }
    },
    redirectToFeature(feature, name) {
      name = name.replace('&', '');
      this.showMenu = false;
      this.$router.push({
        path: `/${this.$store.state.landingPage.currView}/${feature.replace(
          / +/g,
          ''
        )}`,
        hash: (name && name.replace(/ +/g, '')) || ''
      });
    },
    clickContactUs() {
      callGAEvent(this.$gtag, {
        eventName: 'contact_us_btn',
        category: 'menu_click'
      });
      this.showContactForm = true;
    },
    closeContactForm() {
      this.showContactForm = false;
    },
    redirectToDashboard() {
      if (this.$store.state.user.state.loggedIn) {
        if (this.productName == PRODUCT_CODES.firefighter) {
          this.$router.push('/onboarding/pension-details');
        } else {
          this.$router.push('/dashboard/landing');
        }
        callGAEvent(this.$gtag, {
          eventName: 'my_dashboard_btn',
          category: 'btn_click'
        });
      } else {
        this.$router.push('/onboarding/login');
        callGAEvent(this.$gtag, {
          eventName: 'login_btn',
          category: 'btn_click'
        });
      }
    }
  }
};
</script>
<style>
.navbar.vue-fixed-header--isFixed {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
}

.cipp-gradient-purple {
  background-image: linear-gradient(to top, #714fd1, #6a53fa);
}

.nav {
  background-color: #333;
  overflow: hidden;
  color: #000;
  z-index: 0;
}

.nav-btn {
  border-radius: 23.5px;
  border: solid 1px #ffffff;
  color: #ffffff;
}
.contact-btn {
  width: 10rem !important;
}
.text--white {
  color: #ffffff !important;
  text-transform: none !important;
}
.v-tabs-slider {
  background-color: #ffffff;
}
.theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent !important;
}

button:focus {
  outline: none !important;
}

.v-card__title {
  word-break: break-word !important;
}

.feature-menu .v-expansion-panel-content__wrap {
  padding-left: 10px;
}

.pensionbee-img {
  height: 64px;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
