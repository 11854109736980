<template>
  <div>
    <div class="mr-6 mt-2">
      <div class="d-flex">
        <v-menu
          v-if="
            productName !== PRODUCT_CODES.pensionbee &&
              productName !== PRODUCT_CODES.firefighter
          "
          offset-y
          open-on-hover
          content-class="feature-menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              class="text--white nav-header-btn font-regular"
              color="#fff"
              plain
              v-bind="attrs"
              v-on="on"
              >Features</v-btn
            >
          </template>
          <v-card class="d-flex black--text p-2" tile>
            <div v-for="(featureVal, index) in featureNames" :key="index">
              <v-list class="mr-2" dense shaped>
                <v-list-item
                  @click="redirectToFeature(featureVal.toLowerCase(), '')"
                >
                  <v-list-item-icon class="mr-3">
                    <v-icon
                      v-if="index == 0 || index == 2"
                      class="black--text"
                      >{{
                        index == 0 ? 'account_balance' : 'compare_arrows'
                      }}</v-icon
                    >
                    <img
                      v-else
                      alt="benchmark-irr-icon"
                      src="../../assets/Icons/benchmark-irr-icon-blacksvg.svg"
                      height="100%"
                      width="100%"
                    />
                  </v-list-item-icon>
                  <v-list-item-title>{{ featureVal }}</v-list-item-title>
                </v-list-item>
                <v-divider class="ml-2 mb-2"></v-divider>
                <v-list-item
                  v-for="(featureName, i) in featureList[featureVal]"
                  :key="i"
                  @click="
                    redirectToFeature(
                      featureVal.toLowerCase(),
                      featureName.toLowerCase()
                    )
                  "
                >
                  <v-list-item-title>{{ featureName }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </v-card>
        </v-menu>
        <v-btn
          text
          class="text--white font-regular"
          color="#fff"
          plain
          @click="
            $router.push('/aboutus');
            callGAEvent($gtag, {
              eventName: 'about_us_btn',
              category: 'btn_click'
            });
          "
          >About Us</v-btn
        >
        <v-btn
          text
          class="text--white font-regular"
          color="#fff"
          plain
          @click="
            callGAEvent($gtag, {
              eventName: 'help_btn',
              category: 'menu_click'
            });
            $router.push('/help');
          "
          >Help</v-btn
        >
        <v-btn
          text
          class="text--white font-regular"
          color="#fff"
          plain
          @click="clickContactUs()"
          >Contact Us</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
import { callGAEvent } from '~/plugins/utils.js';
import { PRODUCT_CODES } from '~/constants';
export default {
  props: ['featureNames', 'clickContactUs', 'featureList', 'redirectToFeature'],
  data() {
    return {
      productName: this.$productName,
      PRODUCT_CODES
    };
  },
  methods: {
    callGAEvent
  }
};
</script>
